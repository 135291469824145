<template>

    <div style="overflow-x:auto;">
    <table>
        <tr>
            <th>started</th>
            <th>end</th>
            <th>renew</th>
        </tr>
        <tr 
            v-for="(oneSubDetail, index) in subDetails"
            :key="index"
            >
            <td class="center-table">{{ oneSubDetail.current_period_start }}</td>
            <td class="center-table">{{ oneSubDetail.current_period_end }}</td>
            <td class="center-table switch-align">
                <v-switch
                    v-if="!buttonStates[index]"
                    :input-value="!oneSubDetail.cancel_at_period_end"
                    hide-details="true"
                    @change="cancelEvent(oneSubDetail.subscription_id, index, $event)"
                />
                <v-switch
                    v-else
                    loading="warning"
                    :input-value="!oneSubDetail.cancel_at_period_end"
                    hide-details="true"
                />                
            </td>
        </tr>
    </table>
    </div>

</template>

<script>

export default {
    name: 'subscription',
    props: ['subDetails', 'newgenSub'],
    data() {
        return {
            buttonStates: []
        }
    },
    methods: {
    cancelEvent(subId, index, event) {
      this.$set(this.buttonStates, index, true)
      console.log(subId, event)
      const changeRenew = {
          data: {
            newState: !event,
            subscription_id: subId 
          }
      }
      this.$store.dispatch('setSubscriptionRenew', changeRenew).then(() => {
         this.$set(this.buttonStates, index, false)
      })
    },
    },
    mounted() {
        this.subDetails.forEach(subButt => {
            this.buttonStates.push(false)
        });
    }
}
</script>

<style scoped>
    .subscription-container {
        width : 100%;
        background: gray;
    }
    .center-table, td {
        text-align: center; 
        vertical-align: middle;
    }
    .switch-align {
        right: -18px;
        position: relative;
    }
    .v-input--selection-controls {
        margin-top: 0!important;
    }
</style>