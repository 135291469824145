<template>
  <v-container
    id="neworder"
    fluid
    class="full-height"
    tag="section"
  >

    <!--  {{ buttonBusyState }} -->
    <!--  {{ networks }} -->
     <!--  {{ subInfo }}  -->
     <!-- {{ isPaymentFailed }} -->
    <!-- {{ fetchedOrLiveUser }} -->

    <v-dialog
      v-model="dialog3"
      max-width="80%"
      style="z-index: 20000;"
    >
      <v-card>
        <v-card-title>
          <h2 style="margin-bottom: 30px;">General Terms and Conditions</h2>
          
          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="dialog3 = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6">
          <span>
          1 General and scope of application<br>
          The Contractor:<br>
          Data Controller: Pompadur S.r.o.<br>
          Registered office: Hlavná 22 , Štúrovo, Slovakei 943 01<br>
          E-mail: info@pompadur-sro.com<br>
          Company registration number: 53 849 663<br><br>
          Pompadur S.r.O. is the operator of Business Booster Web Application Platform (https://app.bbooster.net), (hereinafter: BBooster) which generates so-called interest in the products of our contracting partners. Our contracting partners have access to the data of interested parties on the BBooster Internet Platform. 
The contract is concluded between Pompadur S.r.O. and the customer, who can be a natural person or a legal entity.<br><br>
2. Price and service<br><br>
2.1.The contents of the package of rooms are always interested parties who have shown a serious interest in the services of Limbic Arc.<br>
2.2.2 The content of the package is the name, email address, telephone number and the city of residence of the interested party.<br>
2.3 The product package will contain 20 names per 30-day periodicity, which will be delivered electronically to the customer in the order of the arrival of the interested parties via the BBooster platform.<br>
2.4 An interested party is defined as a person who, after the Company's online selection in several steps, shows interest and willingness to purchase the product. In all cases, interested parties know the price of the product, have basic product information knowledge and require additional information to purchase or register for the product.<br>
2.5 They can purchase the product package at any time. A period lasts 30 days from the day the product is purchased.<br>
2.6 The price of the product bundle is EUR 240,which does not include AFA for the country concerned.<br><br>
3. Obligations of the buyer<br><br>
3.1 The customer is obliged to contact by telephone the above mentioned interested parties within 48 hours after uploading the Platform and after providing the contact information (name, phone number, varos, profile).<br>
3.2 The client is obliged to contact the interested party within 48 hours after contacting the interested party and to fill in the x things recorded in our system and to provide full feedback.<br><br>
4. Guarantees<br><br>
4.1 The company makes no guarantee that the enquirer will become a customer, register or that they will change their mind about buying or enquiring within 48 hours.<br>
4.2 If it can be demonstrated that the quality of the enquirer differs to such an extent that it can be demonstrated that he/she had no serious intention to purchase the product, in which case our company will replace the enquirer with a new enquirer.  Evidence will be deemed to exist if the interested party is not available on the telephone number provided or if the number does not belong to the name of the interested party.<br>
4.3 In the event of a system failure in the Company's IT system, the Company undertakes to replace the number of interested parties to correspond to the number of interested parties described in section 2.3. In this case, the service may exceed the time period specified in 2.5 until the company reaches the number of enquiries referred to in 2.5.<br>
4.4. In the event of a complaint, the company can be contacted at info@pompadur-sro.com.<br><br>
5. Purchase of the product<br><br>
5.1.  If any of these conditions are not met, the contract will not be concluded.<br>
5.2 It is the buyer's responsibility to read the contract, the company is not obliged to provide any further information other than the contract. The company is not obliged to explain the terms of the contract in any other way.<br><br>
6. Invoicing<br><br>
After the purchase of the rooms, an invoice will be sent automatically to the email address provided by the buyer.<br><br>
7. Termination of service<br><br>
7.1 The term of this contract is 30 days and upon expiry, the contract will be automatically extended for a further period as indicated in 2.5.<br>
7.2 The customer may cancel the service at any time on the Company's account tomorrow. The company will not accept any other form of cancellation of the contract.<br>
7.3The company will inform the contracting partner of the automatic renewal by email 10 days before the start of the next periodic periodic period.<br>
          </span>
        </v-card-text>
        <v-card-actions class="action-container">
          <v-btn
            class="mr-3"
            text
            @click="dialog3 = false"
          >
            Decline
          </v-btn>

          <v-btn
            color="success"
            text
            @click="dialog3 = false"
          >
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-row
    >
      <v-col
        cols="12"
        class="mx-1 px-2"
        v-if="isPaymentFailed && isNewPurchase"
      >
        <base-material-alert
                color="red"
                dark
                dismissible
                icon="mdi-bell"
                class="invalid-vat-notification"
              >
                Sorry, your card payment is failed.
        </base-material-alert>
      </v-col>

      <v-col
        cols="12"
        class="mx-1 px-2"
        v-if="blockPage"
      >
        <base-material-alert
                color="red"
                dark
                icon="mdi-bell"
                class="invalid-vat-notification"
            
              >
                Subscription can not be started: your profile is unfilled.
        </base-material-alert>
      </v-col>      

      <v-col
        cols="12"
        class="mx-1 px-2"
        v-if="!isPaymentFailed && isNewPurchase"
      >
        <base-material-alert
                color="green"
                dark
                dismissible
                icon="mdi-bell"
                class="invalid-vat-notification"

              >
                Thank you. Your payment was successful. Our system started working for you.
        </base-material-alert>
      </v-col>
    </v-row>



    <v-row>
      <v-col
        sm="12"
        md="4"
        v-for="(network, index) in networks"
        :key=index
      >
        <base-material-card
          color="transparent"
          image
        >
          <v-card-title class="justify-center font-weight-light">
            {{ network.name }}
          </v-card-title>

          <v-card-text class="text-body-1 text-center mb-3 font-weight-light grey--text">
            {{ network.description }}
          </v-card-text>

          <v-row>
            <v-col sm="12">
            <template
              v-if="!subInfo[index]"
            >
                <v-card-text 
                  class="text-body-1 text-center mb-3 font-weight-light"
                >
                  Status: INACTIVE 
                </v-card-text>
            </template>

            <template
              v-else
            >
                <v-card-text 
                  class="text-body-1 text-center mb-3 font-weight-light"
                >
                  Status: ACTIVE
                </v-card-text>
               
                <subscription :subDetails="subInfo[index]" :newgenSub="getSubscriptions" />

            </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" class="text-center">
              <v-btn 
                @click="submit(index, network.stripe_price_id, network.net_id, network.entry_number)"
                color="primary"
                :disabled="buttonBusyState[index] || blockPage || !network.stripe_price_id"
              >
                <v-icon
                  medium
                  color="white"
                  class="mr-2"
                >
                mdi-plus-circle-outline
                </v-icon>
              Subscribe
                <template
                  v-if="buttonBusyState[index]"
                >
                  <v-progress-circular
                    indeterminate
                    color="red"
                    style="ml-3"
                  ></v-progress-circular>
                </template>
              </v-btn>
            </v-col>
          </v-row>
          <template v-slot:image>
            <v-img :src="network.image" />
          </template>

          <template v-slot:actions>
            <div 
              class="text-h3 font-weight-light"
              v-if="!vatFree"
            >
              {{ network.price }} EUR + VAT / {{ network.entry_number }} leads
            </div>
            <div 
              class="text-h3 font-weight-light"
              v-else
            >
              {{ network.price }} EUR / {{ network.entry_number }} leads
            </div>

            <v-spacer />

            <span class="text-caption grey--text font-weight-light">
              <v-icon small>mdi-map-marker</v-icon>

              HUN / INT
            </span>
          </template>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import { loadStripe } from "@stripe/stripe-js"
import subscription from "./component/Subscription"

export default {
  name: "DashboardDashboard",
  components: {
    subscription
  },
  props: ['fetchedOrLiveUser', 'liveFeed'],
  data() {
    return {
      isPaymentFailed: false,
      isNewPurchase: false,
      buttonBusyState: [],
      stripe: null,
      elements: null,
      loading: true,
      lineItems: [
        {
          price: 'price_1K49DSBC6X0caFm9VTNKMjl6',
          quantity: 1,
        },
      ],
      successURL: 'https://app.bbooster.net/#/neworder',
      cancelURL: 'https://app.bbooster.net/#/neworder',
      dialog3: false,
      taxRates: ['txr_1K7jFwBC6X0caFm9J4rWo8bO', 'txr_1K7jFEBC6X0caFm9SaIXinnN', 'txr_1K7h96BC6X0caFm9EViEot83'],
      style: {
        style: {
          base: {
            padding: "1em",
            iconColor: "#000",
            color: "#000",
            backgroundColor: "transparent",
            borderRadius: "10px",
            fontWeight: "800",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
              color: "#fce883"
            },
            "::placeholder": {
              color: "#3d3d3d"
            }
          },
          invalid: {
            iconColor: "red",
            color: "red"
          },
          complete: {
            color: "green",
            iconColor: "green"
          }
        },
        hidePostalCode: true,
        iconStyle: "solid",
        padding: "1em",
      },

    };
  },

  computed: {
    ...mapGetters(["networks", "networkColors", "getPlans"]),

    blockPage() {
      return (!this.fetchedOrLiveUser.stripe_id)
    },

    subInfo() {
      const subInfoArray = []
      console.log('processing this.getSubscriptions: ', this.getSubscriptions);
      if (this.getSubscriptions) {
          this.networks.forEach( oneNetwork => {
            subInfoArray.push(this.subData(oneNetwork.stripe_price_id))
          })
      } else {
          this.networks.forEach( () => {
            subInfoArray.push(false)
          })
      }
      console.log('processing subInfoArray: ', subInfoArray)
      return subInfoArray
      },

      getSubscriptions() {
        return this.$store.getters.getSubscriptions
      },

      vatFree() {
        if (this.fetchedOrLiveUser.tax_number && this.fetchedOrLiveUser.is_company) {
          return true
        } else {
          return false
        }
      }
  },

  created() {

  },

  async mounted() {

    this.networks.forEach( oneNetwork => {
      this.buttonBusyState.push(false)
    })

    /* this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY) */
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY_LIVE)

    if (localStorage.getItem('stripesession')) {
        fetch("https://bboosterstripe.herokuapp.com/retrievesession?id=" + localStorage.getItem('stripesession'))
        .then((response) => response.json())
        .then((session) => {
          console.log('session data: ', session)
          this.isNewPurchase = true
          console.log('status: ', session.payment_status)
          if (session.payment_status !== 'paid') {
            this.isPaymentFailed = true
          }
        })
        .catch((error) => {
          console.error('Error: ', error)
        })

        localStorage.removeItem('stripesession')
    }

  },

  methods: {
    timeConverter(UnixTimestamp){
        const a = new Date(UnixTimestamp * 1000);
        const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        const date = a.getDate();
/*         const hour = a.getHours();
        const min = a.getMinutes(); */
        /* const sec = a.getSeconds(); */
        const time = date + ' ' + month + ' ' + year /* + ' ' + hour + ':' + min */;
        return time;
    },
    subData(id) {
      const filteredSub = this.getSubscriptions.filter( value => {
        return (value.plan.id === id && value.status === 'active')
      });
      if (filteredSub.length === 0) {
        return false
      }
      const infoArray = []
      filteredSub.forEach(instance => {
          infoArray.push({
            current_period_start: this.timeConverter(instance.current_period_start),
            current_period_end: this.timeConverter(instance.current_period_end),
            subscription_id: instance.id,
            cancel_at_period_end: instance.cancel_at_period_end,
          })
      })
      return infoArray
    },
    async submit(index, priceID, netId, entryNumber) {
      console.log('submit triggered: ', index)
      console.log('target: ', this.buttonBusyState[index])
      this.$set(this.buttonBusyState, index, true)

      const sessionData = {
        data: {
          customer_id : this.fetchedOrLiveUser.stripe_id,
          price : priceID,
          amount : 1,
          metadata : {
            net_id : netId,
            entry_number : entryNumber
          }
        }
      }

        try {
          const response = await fetch("https://bboosterstripe.herokuapp.com/createsession", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(sessionData)
          });
          const res = await response.json()
          this.$set(this.buttonBusyState, index, false)
          localStorage.setItem('stripesession', res.id)
          this.stripe.redirectToCheckout({ sessionId: res.id })
        } catch (error) {
          console.log('error creating session: ', error)
          this.$set(this.buttonBusyState, index, false)
        }

    },
    redirect() {
        this.stripe.redirectToCheckout({
          successUrl: this.successURL,
          cancelUrl: this.cancelURL,
          lineItems: this.lineItems,
            automatic_tax: {
              enabled: true,
            },
          mode: "subscription"
        });
    }
  },
};
</script>

<style scoped>
.full-height {
    min-height: 87vh;
}
.switch-board {
    display: flex;
    justify-content: center;
}
.action-container {
    display: flex;
    justify-content: center;
}
.v-progress-circular {
  margin-left: 1rem;
}

</style>

<style>
.v-btn__content > .v-progress-circular > svg {
    width: 60%!important;
    height: 60%!important;
}

.credit-card-slot {
    background-color: #ffdfea;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
}

.valid-card {
    color: green
}

.invalid-vat-notification {
  position: fixed;
  top: 0;
  width: 80%;
  margin-right: 20px;
  z-index: 100;
}
</style>
